<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-snackbar
        v-model="showSnackBar"
        color="#3F033B"
        right
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #000">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center class="login_page" py-4>
        <v-flex xs12 sm8 md7 lg5 xl5 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl8>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 15px;
                            color: #b8b8b8;
                          "
                        >
                          Welcome to
                        </span>
                      </v-flex>
                      <v-flex xs12 py-4>
                        <router-link to="/">
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            Periyar Tiger Reserve
                          </span>
                        </router-link>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs6 lg4 px-2>
                            <router-link to="/Login">
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Log in
                              </span>
                            </router-link>
                          </v-flex>
                          <v-flex xs6 lg4 px-2>
                            <v-layout wrap justify-center>
                              <v-flex xs12>
                                <span
                                  style="
                                    font-family: poppinssemibold;
                                    font-size: 15px;
                                    color: #000;
                                  "
                                >
                                  Sign up
                                </span>
                              </v-flex>
                              <v-flex xs6>
                                <v-progress-linear
                                  height="2"
                                  value="100"
                                  color="#3F033B"
                                ></v-progress-linear>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-4>
                        <v-layout wrap>
                          <v-flex xs12 text-left>
                            <span
                              style="
                                font-family: ;
                                font-size: 14px;
                                color: #ababab;
                              "
                              class="pl-3"
                            >
                              Name
                            </span>
                            <v-text-field
                              color="#828282"
                              placeholder="Name"
                              outlined
                              dense
                              v-model="name"
                              hide-details
                            ></v-text-field>
                            <span
                              style="
                                font-family: ;
                                font-size: 14px;
                                color: #ababab;
                              "
                              class="pl-3"
                            >
                              Email
                            </span>
                            <v-text-field
                              color="#828282"
                              placeholder="Email"
                              outlined
                              dense
                              v-model="email"
                              hide-details
                            ></v-text-field>
                            <span
                              style="
                                font-family: ;
                                font-size: 14px;
                                color: #ababab;
                              "
                              class="pl-3"
                            >
                              Phone
                            </span>
                            <v-text-field
                              color="#828282"
                              placeholder="Phone"
                              outlined
                              dense
                              v-model="phone"
                              hide-details
                            ></v-text-field>
                            <span
                              style="
                                font-family: ;
                                font-size: 14px;
                                color: #ababab;
                              "
                              class="pl-3"
                            >
                              Password
                            </span>
                            <v-text-field
                              type="password"
                              color="#828282"
                              placeholder="Password"
                              outlined
                              dense
                              v-model="password"
                              hide-details
                            ></v-text-field>
                            <span
                              style="
                                font-family: ;
                                font-size: 14px;
                                color: #ababab;
                              "
                              class="pl-3"
                            >
                              Confirm Password
                            </span>
                            <v-text-field
                              color="#828282"
                              type="password"
                              placeholder="Confirm Password"
                              outlined
                              dense
                              v-model="confirmPassword"
                              hide-details
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <v-layout wrap justify-end>
                      <v-flex xs12 md4 pt-4 text-right>
                        <v-btn
                          block
                          tile
                          color="#3F033B"
                          depressed
                          @click="validateInput"
                          :ripple="false"
                          class="itemValue"
                        >
                          Continue
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                  <v-flex py-4>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center pb-4 align-center>
                  <v-flex text-left pl-8>
                    <router-link to="/Login">
                      <span
                        style="
                          font-family: poppinsmedium;
                          font-size: 12px;
                          color: #040404;
                          cursor: pointer;
                        "
                      >
                        Already a member?
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      name: null,
      email: null,
      phone: null,
      country: null,
      password: null,
      confirmPassword: null,
    };
  },

  methods: {
    validateInput() {
      if (!this.name) {
        this.msg = "Please provide your Name";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please provide your Email";
        this.showSnackBar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide your Phone Number";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please provide your Password";
        this.showSnackBar = true;
        return;
      } else if (!this.confirmPassword) {
        this.msg = "Please confirm your Password";
        this.showSnackBar = true;
        return;
      } else if (this.password != this.confirmPassword) {
        this.msg = "Passwords doesn't match";
        this.showSnackBar = true;
        return;
      } else {
        this.signUp();
      }
    },
    signUp() {
      var userData = {};
      userData["name"] = this.name;
      userData["email"] = this.email;
      userData["phone"] = this.phone;
      userData["password"] = this.password;
      this.$store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "/user/guest/signup",
        data: userData,
      })
        .then((response) => {
          this.$store.commit("appLoading", false);
          if (response.data.status) {
            this.$store.commit("userType", response.data.role);
            this.$store.commit("userData", response.data.userdata);
            this.$store.commit("loginUser", response.data.token);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>